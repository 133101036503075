import React from "react";
import { graphql, PageProps, Link } from "gatsby";
import { Layout } from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Section } from "../components/Section";
import { SectionHeading } from "../components/SectionHeading";
import { SectionIntro } from "../components/SectionIntro";
import { Button } from "../components/Button";

interface NotFoundPageProps extends PageProps {
  pageContext: {
    id: string;
    slug: string;
    title: string;
  };
}

const NotFound: React.FC<NotFoundPageProps> = () => (
  <Layout>
    <Breadcrumbs title="Not Found" />
    <Section>
      <SectionHeading>Page Not Found</SectionHeading>
      <SectionIntro>Please check the link, or try again.</SectionIntro>
      <Link to="/">
        <Button palette="primary">Go back to Home</Button>
      </Link>
    </Section>
  </Layout>
);

export const pageQuery = graphql`
  query {
    allWordpressWpService {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
`;

export default NotFound;
